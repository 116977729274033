<template>
	<div>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<div class='btn btn-primary' @click='testMintNft()'>Minter un NFT</div>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
	</div>
</template>


<script>

import { testMintNft } from '@/libs/test';

export default {
	name: 'Test',
	components: {

	},

	mounted: function(){



	},
	data: function () {

		return {

		}
	},
	props: {

	},
	methods: {

		testMintNft: function() {
		
			window.solana.connect();
			
			window.solana.on("connect", () => {

				testMintNft(window.solana);
			});
		}

	},
	created: async function() {



	},
}
</script>

