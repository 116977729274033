import {
  Account,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

import * as anchor from '@project-serum/anchor';

import { establishConnection } from '@/libs/solanaConnection';

let connection: Connection;

export async function testMintNft(wallet_provider) {
	
	var program_id = new PublicKey('GhUxJVXuvy6vk9wAU617x2QFLFwThF5q51hTsbbtXjgY');
	
	connection = await establishConnection();
	
	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	
	// pour les tests seulement
	const payer = anchor.web3.Keypair.generate();
	const mintAuthority = anchor.web3.Keypair.generate();
	
	// Airdropping tokens to a payer. (pas nécessaire normalement, pour les tests seulement)
	await provider.connection.confirmTransaction(
		await provider.connection.requestAirdrop(payer.publicKey, 2000000000),
		"confirmed"
	);
	
	// on crée le NFT pour le vendeur (pas nécessaire normalement, pour les tests seulement)
	var NFT = await Token.createMint(
	
		connection,
		payer,
		mintAuthority.publicKey,
		null,
		0,
		TOKEN_PROGRAM_ID
	);
	
	// on crée le token account pour le vendeur (pas nécessaire normalement, pour les tests seulement)
	var tokenAccount = await NFT.createAccount(wallet_provider.publicKey);
	
	// on lui envoie le nft (pas nécessaire normalement, pour les tests seulement)
	await NFT.mintTo(
		tokenAccount,
		mintAuthority.publicKey,
		[mintAuthority],
		1
	);
	
	return true;
}